<template>
  <div class="systemInfo wr">
    <div class="info-a wr f-cb">
      <div class="left wow fadeInLeft animated animated animated" style="visibility: visible;">
        <div class="pinfo-container wr swiper-container-horizontal swiper-container-android">
        <div class="swiper-wrapper">
        <div class="swiper-slide swiper-slide-active">
          <img src="../../../img/acquisitionSystem.jpg" alt="">
        </div>
        </div>
        </div>
        </div>
        <div class="right ">
        <div class="tit-60 mc hnb wow fadeInRight animated animated animated" style="visibility: visible;">物联网IoT设备管理与数据采集系统</div>
        <div class="tit-18 t1 hnb  wow fadeInRight animated animated animated" style="visibility: visible;">
        <p style="line-height: 1.75em; text-align: justify;">
          <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">
            物联网IoT设备管理与数据采集系统整合了传感器、智能计量设备和其他IoT设备，覆盖了供水系统的各个环节。系统通过集中管理，实时监控和控制供水过程。
          </span>
        </p>
        <p style="line-height: 1.75em;"><br></p>
        </div>
      </div>
    </div>
    <div class="view">
      <div class="info-b wr">
        <div class="tit-48 mc lh1 hnb wow fadeInUp animated animated animated" style="visibility: visible;">产品特点</div>
        <div class="list f-cb">
          <div class="li wow fadeInUp animated animated animated" style="visibility: visible;"> 
            <div>
              <div class="icon">
                <img :src="require('@/img/productFeatures-icon.png')" alt="设备注册与管理">
              </div>
              <div class="tit-24 t1 hnb">设备注册与管理</div>
            </div>
            <div class="tit-16 t2 text-justify">
              <p style="line-height: 1.75em; text-align: justify;">
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">
                  系统提供中心化平台，用于注册、  配置和管理所有 IoT 设备，确保设备运行的可靠性和安全性。
                </span>
              </p>
            </div>
          </div>
          <div class="li wow fadeInUp animated animated animated" style="visibility: visible;">
            <div class="icon">
              <img :src="require('@/img/productFeatures-icon.png')" alt="实时数据采集">
            </div>
            <div class="tit-24 t1 hnb">实时数据采集</div>
            <div class="tit-16 t2 text-justify">
              <p style="line-height: 1.75em; text-align: justify;">
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">
                  自动收集设备运行数据（如流量、压力、水质等），为运营决策提供实时支持。
                </span>
              </p>
            </div>
          </div>
          <div class="li wow fadeInUp animated animated animated" style="visibility: visible;">
            <div class="icon">
              <img :src="require('@/img/productFeatures-icon.png')" alt="智能故障检测与诊断">
            </div>
            <div class="tit-24 t1 hnb">智能故障检测与诊断</div>
            <div class="tit-16 t2 text-justify">
              <p style="line-height: 1.75em; text-align: justify;">
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">
                  系统自动识别设备故障或异常，并通知运维人员迅速响应和解决问题。
                </span>
              </p>
            </div>
          </div>
          <div class="li wow fadeInUp animated animated animated" style="visibility: visible;">
            <div class="icon">
              <img :src="require('@/img/productFeatures-icon.png')" alt="数据分析与报告">
            </div>
            <div class="tit-24 t1 hnb">数据分析与报告</div>
            <div class="tit-16 t2 text-justify">
              <p style="line-height: 1.75em; text-align: justify;">
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">
                  系统分析收集的数据，生成报表和优化建议，帮助提升供水效率和服务质量。
                </span>
              </p>
            </div>
          </div>
          <div class="li wow fadeInUp animated animated animated" style="visibility: visible;">
            <div class="icon">
              <img :src="require('@/img/productFeatures-icon.png')" alt="远程设备控制">
            </div>
            <div class="tit-24 t1 hnb">远程设备控制</div>
            <div class="tit-16 t2 text-justify">
              <p style="line-height: 1.75em; text-align: justify;">
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">
                  提供远程控制功能，允许运营人员远程调整设备设置，应对紧急情况，提升操作灵活性。
                </span>
              </p>
            </div>
          </div>
          <div class="li wow fadeInUp animated animated animated" style="visibility: visible;">
            <div class="icon">
              <img :src="require('@/img/productFeatures-icon.png')" alt="预测性维护与优化">
            </div>
            <div class="tit-24 t1 hnb">预测性维护与优化</div>
            <div class="tit-16 t2 text-justify">
              <p style="line-height: 1.75em; text-align: justify;">
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">
                  系统利用机器学习算法，分析历史与实时数据，预测设备的维护需求和潜在故障点。帮助减少停机时间，优化维护计划，降低运营成本。
                </span>
              </p>
            </div>
          </div>
        </div>  
      </div>
    </div>
  </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        
    },
    mounted() {
        
    }
}
</script>
<style lang="less" scoped>
.mc {
    color: #0041c3;
}
.lh1 {
    line-height: 1;
}
.tit-48 {
    line-height: 1.8;
    font-size: 48px;
}
// web端样式
.systemInfo {
  background-image: url(../../../img/bg3.jpg);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  // padding-top: 100px;
  padding-bottom: 4.5rem;
  .info-a {
    padding-top: 7.38rem;
    padding-bottom: 6rem;
    padding-left: 9rem;
    .left {
      float: left;
      width: 44.8%;
      .pinfo-container {
          overflow: hidden;
      }
    }
    .right {
        float: left;
        width: 55.2%;
        padding: 32px 190px 0 89px;
        // padding-left: 2.225rem;
        // padding-right: 4.75rem;
        // padding-top: .8rem;
      .mc {
        border-bottom: 1px solid rgba(0, 65, 195, .2);
        line-height: 1;
        padding-bottom: 2.5rem;
        position: relative;
      }
      .mc::after {
        content: "";
        width: 60px;
        height: 6px;
        background: #0041C3;
        position: absolute;
        bottom: 0;
        left: 0;
      }
      .t1 {
        color: #000000;
        margin-top: 3.31rem;
      }
    }
  }
  .view {
    width: 100%;
    padding: 0 190px;
    .info-b {
      background-color: #fff;
      padding: 76px 118px;
      margin-top: 20px;
      .list {
        width: 100%;
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        .li {
            width: calc(39.5%);
            // width: calc((100% - 115px) / 2);
            margin-right: 115px;
            padding: 40px 0;
            border-bottom: 1px solid rgba(207, 214, 229, 1);
            .icon {
              display: inline-block;
              vertical-align: middle;
              margin-bottom: 0.63rem;
            }
            .t1 {
                display: inline-block;
                margin-top: 1.25rem;
                margin-bottom: 1rem;
                margin-left: 0.8rem;
                position: relative;
            }
        }
      }
    }
  }
}
// 移动端样式
@media screen and (max-width:650px) {
    .home-bg1 {
        margin-top: -44px;
        .cycle-wrapper {
            padding: 44px 0px;
            .content {
                padding: 5px;
                margin: 0 35px;
                .con-title {
                    font-size: 14px;
                }
                .con-text {
                    font-size: 14px;
                    line-height: 35px;
                }
            }
        }
    }
}
// </style>
// <style>
/* 移动端样式 */
/* @media screen and (max-width:650px) {
    .el-breadcrumb__inner {
        font-size: 14px !important;
    }
    .el-breadcrumb__separator {
        font-size: 14px !important;
    }
}*/
</style>